import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { isArray } from 'lodash';
import React from 'react';
import Divider from '../ui/Divider';
import Flex, { FlexProps } from '../ui/Flex';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardMetaProps<D extends React.ElementType = 'h2'> = TxtProps<
  D,
  {
    wrapProps?: FlexProps;
  }
>;

export const CardMeta = <D extends React.ElementType>({
  children,
  wrapProps,
  ...props
}: CardMetaProps<D>) => {
  const { meta, metaDivider = true } = useCard();
  const content = children ?? meta;

  const hasContent = !!content && (!isArray(content) || content.length);

  return hasContent ? (
    content ? (
      <Flex direction="row" columnGap="3xs" rowGap="4xs" {...wrapProps}>
        {content.map((item: string, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <Txt as="span" {...mergePropsClassName(props, style.meta)}>
                {item}
              </Txt>
              {metaDivider && idx !== content.length - 1 && (
                <Divider direction="vertical" cx={{ color: 'accent' }} style={{ height: '1em' }} />
              )}
            </React.Fragment>
          );
        })}
      </Flex>
    ) : (
      <Txt as="span" {...mergePropsClassName(props, style.meta)}>
        {content}
      </Txt>
    )
  ) : null;
};
