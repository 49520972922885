import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslations as getTranslations } from 'next-intl';

dayjs.extend(isBetween);

export const createEventDate = (
  eventStartDate: string | undefined,
  eventEndDate: string | undefined
): { eventDate: string | null; isPast: boolean | null } => {
  const t = getTranslations('article');

  if (!eventStartDate && !eventEndDate) {
    return { eventDate: null, isPast: null };
  }

  const startDate = eventStartDate ? dayjs(eventStartDate, 'D MMMM YYYY') : undefined;
  const endDate = eventEndDate ? dayjs(eventEndDate, 'D MMMM YYYY') : undefined;
  const currentDate = dayjs();

  const startIsFuture = startDate ? currentDate.isBefore(startDate, 'day') : false;
  const endIsFuture = endDate ? currentDate.isBefore(endDate, 'day') : false;

  const isOngoing =
    startDate && endDate ? currentDate.isBetween(startDate, endDate, 'day', '[]') : false;

  if (isOngoing) {
    return {
      eventDate: t('eventRunning', {
        startDate: startDate?.format('D MMMM YYYY'),
        endDate: endDate?.format('D MMMM YYYY'),
      }),
      isPast: false,
    };
  }

  if (startDate && !endDate) {
    return {
      eventDate: startIsFuture
        ? t('eventToBeHeld', { date: startDate.format('D MMMM YYYY') })
        : t('watchOnDemand'),
      isPast: !startIsFuture,
    };
  }

  if (endDate && !startDate) {
    return {
      eventDate: endIsFuture
        ? t('eventToBeHeld', { date: endDate.format('D MMMM YYYY') })
        : t('watchOnDemand'),
      isPast: !endIsFuture,
    };
  }

  if (startDate && endDate) {
    const bothDatesInFuture = startIsFuture && endIsFuture;

    if (bothDatesInFuture || isOngoing) {
      return {
        eventDate: t('eventRunning', {
          startDate: startDate.format('D MMMM YYYY'),
          endDate: endDate.format('D MMMM YYYY'),
        }),
        isPast: false,
      };
    }

    return {
      eventDate: t('watchOnDemand'),
      isPast: true,
    };
  }

  return { eventDate: null, isPast: null };
};
